<style src="../../assets/css/inputeo.css" scoped></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
    .button-action-container{
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        right: 10px;
        top: 5px;
    }
    .button-action {
        background-color: rgb(221, 223, 225);
        width: 18px;
        height: 18px;
        color: #F6F8FA;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: 2px;
        margin: 2px;
    }
    .button-action:hover {
        cursor: pointer;
    }
</style>
<style>
    .inputeo {
        margin-bottom: 15px;
    }

    .inputeo.inputeo-small label {
        font-size: 9px;
        margin: 5px 8px 0px 8px;
        font-weight: 100;
    }

    .inputeo.inputeo-small input {
        padding: 5px 8px 8px 8px;
        font-size: 11px;
    }

    .inputeo.inputeo-small .multiselect__placeholder,
    .inputeo.inputeo-small .multiselect,
    .inputeo.inputeo-small .multiselect__input,
    .inputeo.inputeo-small .multiselect__single {
        min-height: 30px;
        margin: 0;
        padding-left: 0px;
        font-size: 11px;
    }

    .inputeo .multiselect__tags {
        min-height: 32px;
    }

    .inputeo.inputeo-small .multiselect__tags-wrap .multiselect__tags {
        padding: 4px 20px 4px 4px;
    }

    .inputeo.inputeo-small .multiselect__tags {
        min-height: 33px;
        font-size: 9px;
    }

    .inputeo.inputeo-small .multiselect__tag-icon {
        top: -2px;
    }

    .multiselect {
        cursor: pointer;
        min-height: 32px;
    }

    .multiselect__tags {
        background: transparent;
        border: unset;
    }

    .multiselect__tag {
        background: rgb(100, 110, 120) !important;
    }

    .multiselect__tag-icon:after {
        color: #E5E5E5;
    }

    .multiselect__option--highlight, .multiselect__option--selected.multiselect__option--highlight {
        background: #F0F3F6;
        color: unset;
    }

    .multiselect__option--highlight:after {
        background: unset;
    }

    .multiselect, .multiselect__input, .multiselect__single {
        background: transparent;
        font-size: 12px;
    }

    .multiselect__single {
        padding-right: 20px;
        font-size: 14px;
        font-family: montserrat, sans-serif;
        color: rgb(50, 60, 70);
        width: 100%;
        box-sizing: border-box;
        font-weight: 600;
    }

    .inputeo-medium .multiselect__single {
        font-size: 12px;
    }

    .multiselect__placeholder {
        font-size: 12px;
        padding-left: 8px;
        color: rgb(50, 60, 70);
    }

    .multiselect__clear {
        position: absolute;
        right: 30px;
        top: 2px;
        color: rgb(100, 110, 120);
        font-size: 12px;
        padding: 10px;
        cursor: pointer;
        z-index: 2;
        font-style: italic;
    }

    .multiselect__select {
        top: -2px;
    }

    .multiselect--active {
        z-index: 1000000;
    }

    .multiselect--disabled .multiselect__select {
        background: unset;
    }

    .multiselect__spinner {
        background: #F6F8FA;
    }
</style>

<template>
    <div class="inputeo" :class="{ 'inputeo-small': isSmall, 'inputeo-medium': isMedium }">
        <div v-if="displayError && error" class="invalid-mark">
            <div class="invalid-mark-message-container">
                <div class="invalid-mark-message">{{ error }}</div>
            </div>
        </div>
        <label v-if="label">{{ label }}<span v-if="!allowEmpty">&nbsp;*</span></label>
            <div class="button-action-container">
            <div class="button-action" v-if="$slots.iconAction" @click="onButtonActionClick">
                <slot name="iconAction"></slot>
            </div>
            <div class="button-action" v-if="$slots.iconSecondAction" @click="onButtonSecondActionClick">
                <slot name="iconSecondAction"></slot>
            </div>
        </div>
        <VueMultiselect
            :modelValue="modelValue"
            :options="options"
            selectLabel=""
            selectGroupLabel=""
            selectedLabel=""
            deselectLabel=""
            :placeholder="placeholder != null ? placeholder : ''"
            tagPlaceholder=""
            @search-change="asyncSearch"
            :track-by="trackBy"
            label="label"
            @select="onSelect"
            @remove="onRemove"
            :internalSearch="searchable === false"
            :searchable="searchable === true"
            :multiple="multiple === true"
            :allowEmpty="allowEmpty"
            :group-label="groupLabel"
            :group-values="groupValues"
            :group-select="groupSelect"
            :disabled="disabled"
            :class="disabled ? 'disabled' : ''"
            :loading="loading"
            :maxHeight="maxHeight"
            :openDirection="openDirection"
            :taggable="taggable === true"
            @tag="addTag"
        >
            <template v-slot:clear>
                <div class="multiselect__clear" v-if="(modelValue != null && (multiple === false || modelValue.length > 0 )) && (required === false || multiple === true || searchable === true)" @click="clearAll()">X</div>
            </template>
            <template v-slot:noResult>
                <div v-if="hasMinimalCharSearch">Aucun résultat</div>
                <div v-if="!hasMinimalCharSearch">{{ $props.minChars }} caractères requis</div>
            </template>
            <template v-slot:noOptions>Tapez pour rechercher</template>
            <template v-slot:selection="{ values, isOpen }" v-if="!displayTag"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ values.length === 1 ? oneItemSelectedText : multipleItemsSelectedText }}</span></template>
        </VueMultiselect>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueMultiselect from 'vue-multiselect'

    export default {
        name: 'BaseSelect',
        components: {
            VueMultiselect
        },
        props: {
            label: {
                type: String,
                default: ''
            },
            modelValue: {
                type: Object,
                default: null
            },
            defaultOptions: {
                type: Array,
                default: []
            },
            name: {
                type: String,
                default: ''
            },
            displayError: {
                type: Boolean,
                default: false
            },
            error: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: null
            },
            minChars: {
                type: Number,
                default: 3
            },
            api: {
                type: String,
                default: null
            },
            apiParams: {
                type: Object,
                default: null
            },
            fieldValue: {
                type: String,
                default: ''
            },
            fieldLabel: {
                type: String,
                default: ''
            },
            required: {
                type: Boolean,
                default: false
            },
            searchable: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            oneItemSelectedText: {
                type: String,
                default: 'option sélectionnée'
            },
            multipleItemsSelectedText: {
                type: String,
                default: 'options sélectionnées'
            },
            groupLabel: {
                type: String,
                default: null
            },
            groupValues: {
                type: String,
                default: null
            },
            groupSelect: {
                type: Boolean,
                default: false
            },
            allowEmpty: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            isSmall: {
                type: Boolean,
                default: false
            },
            isMedium: {
                type: Boolean,
                default: false
            },
            displayTag: {
                type: Boolean,
                default: false
            },
            maxHeight: {
                type: Number,
                default: 120
            },
            openDirection: {
                type: String,
                default: ''
            },
            optionSelectAll: {
                type: Boolean,
                default: false
            },
            taggable: {
                type: Boolean,
                default: false
            },
            filterDuplicate: {
                type: String,
                default: ''
            },
            trackBy: {
                type: String,
                default: 'value'
            },
            autoComplete: {
                type: Object,
                default: null
            },
            autoCompleteLabel: {
                type: String,
                default: ''
            },
            defaultSelectedValue: {
                type: Object,
                default: null
            },
            allowCustomEntry: {
                type: Boolean,
                default: false
            },
        },
        emits: ['onButtonActionClick', 'onChange', 'update:modelValue', 'autoComplete', 'onButtonSecondActionClick'],
        data() {
            return {
                hasMinimalCharSearch: false,
                options: [],
                isFromUser: false,
                loading: false
            }
        },
        watch: {
            modelValue: {
                handler(modelValue) {
                    if (this.defaultSelectedValue != null && modelValue == null)
                        this.$emit('update:modelValue', this.defaultSelectedValue);

                    this.checkField(modelValue, this.isFromUser ? 'user' : 'auto');
                    this.isFromUser = false;
                },
            },
            defaultOptions: {
                handler(defaultOptions) {
                    let options = [];

                    if (this.fieldLabel && this.fieldValue) {
                        options = defaultOptions.length === 0 ? [] : defaultOptions.map((element) => {
                            return {
                                value: element[this.fieldValue],
                                label: element[this.fieldLabel],
                                data: element
                            }
                        });
                    } else {
                        options = JSON.parse(JSON.stringify(defaultOptions));
                    }

                    if (this.optionSelectAll && options.length > 0) {
                        options.unshift({
                            value: 'system_all',
                            label: '----- Sélectionner tout -----'
                        });
                    }

                    this.options = options;
                },
                deep: true
            },
            required: {
                handler() {
                    this.checkField(this.modelValue, this.isFromUser ? 'user' : 'auto');
                }
            }
        },
        beforeMount() {
            if (this.defaultSelectedValue != null && this.modelValue == null)
                this.$emit('update:modelValue', this.defaultSelectedValue);
        },
        mounted() {
            let options = [];

            if (this.defaultOptions != null) {
                options = JSON.parse(JSON.stringify(this.defaultOptions));

                if (this.optionSelectAll && options.length > 0) {
                    options.unshift({
                        value: 'system_all',
                        label: '--- Sélectionner tout ---'
                    });
                }
            }

            this.options = options;

            this.checkField(this.modelValue, 'init');
        },
        methods: {
            addTag (newTag) {
                this.options.push({
                    value: newTag,
                    label: newTag
                });

                if (this.multiple === true) {
                    let selectedOptions = [];

                    if (this.modelValue) {
                        selectedOptions = [...this.modelValue];
                    }

                    selectedOptions.push({
                        value: newTag,
                        label: newTag
                    });

                    this.$emit('update:modelValue', selectedOptions);
                } else {
                    this.$emit('update:modelValue', {
                        value: newTag,
                        label: newTag
                    });
                }
            },
            checkField(data, changeType) {
                let error = null;

                // On vérifie s'il n'y a pas d'erreur
                if (this.required === true && (data == null || ((this.multiple && data.length === 0) || (!this.multiple && data.value == null)))) {
                    error = `Ce champ est requis`;
                }

                // On émet l'événement pour spécifier qu'il y a un changement
                this.$emit('onChange', {
                    name: this.name,
                    value: data,
                    error: {
                        type: 'validator',
                        message: error
                    },
                    changeType: changeType
                });
            },
            async asyncSearch(query) {
                // Si on ne recherche rien ou pas suffisamment de caractères, on ne fournit aucune option
                if (query === null || query.length < this.minChars) {
                    this.hasMinimalCharSearch = false;
                    this.resetOptions();
                    return [];
                }

                this.hasMinimalCharSearch = true;

                // Si on utilise une API pour fournir les options
                if (this.api !== null) {
                    let payload = {
                        searchedText: query,
                    }

                    for (let apiParamKey in this.apiParams) {
                        if (this.apiParams[apiParamKey] == null) {
                            delete this.apiParams[apiParamKey];
                        }
                    }

                    if (this.apiParams !== null) {
                        payload = Object.assign(payload, this.apiParams);
                    }

                    const params = new URLSearchParams(payload);

                    this.loading = true;

                    let result = await axios
                    .get(this.api + '?' + params, {
                        showPreloader: false
                    });

                    this.loading = false;

                    this.options = [];

                    this.allowCustomEntry && this.options.push({
                            value: query,
                            tempLabel: query,
                            label: query,
                            data: null,
                            isCustomOption : true
                    });

                    result.data.length !== 0 && result.data.forEach(element => {
                        this.options.push(
                            {
                                value: element[this.fieldValue],
                                tempLabel: element[this.fieldLabel],
                                label: element[this.fieldLabel] + (this.filterDuplicate ? ` - ${element[this.filterDuplicate]}` : ''),
                                data: element
                            }
                        );
                    })
                }
            },
            onSelect(selectedOption) {
                let autoCompleteValue = null;
                if (this.multiple === true) {
                    let selectedOptions = [];
                    if (selectedOption.value === 'system_all') {
                        if (this.modelValue.length < this.options.length - 1) {
                            selectedOptions = this.options.filter(option => option.value !== 'system_all');
                        }
                    } else {
                        if (this.modelValue) {
                            selectedOptions = [...this.modelValue];
                        }
                        selectedOptions.push(selectedOption);
                    }

                    this.isFromUser = true;
                    this.$emit('update:modelValue', selectedOptions);
                } else {
                    this.isFromUser = true;
                    if (this.filterDuplicate) {
                        selectedOption.label = selectedOption.tempLabel
                        delete selectedOption.tempLabel
                    }
                    this.$emit('update:modelValue', selectedOption);

                    if(!selectedOption.isCustomOption){
                        autoCompleteValue = {
                            value: selectedOption.data?.[this.fieldValue],
                            label: selectedOption.data?.[this.autoCompleteLabel],
                            data: selectedOption.data
                        }
                        this.$emit('autoComplete', autoCompleteValue);
                    }
                }
                this.resetOptions();
            },
            onRemove(removedValue) {
                if (this.multiple === true) {
                    let selectedOptions = [...this.modelValue];
                    var index = selectedOptions.indexOf(removedValue);
                    if (index !== -1) {
                        selectedOptions.splice(index, 1);
                    }
                    this.isFromUser = true;
                    this.$emit('update:modelValue', selectedOptions);
                }
            },
            resetOptions() {
                if (this.searchable === true) {
                    if (this.defaultOptions.length > 0) {
                        this.options = this.defaultOptions;
                    } else {
                        this.options = [];
                    }
                }
            },
            clearAll() {

                const removedValueWasCustomOption = this.multiple === false ? this.modelValue.isCustomOption :
                this.modelValue.reduce((prev, selectedOption) => {
                    return prev || selectedOption.isCustomOption;
                }, false);

                let selectedValue = this.multiple === true ? [] : null;
                this.isFromUser = true;
                this.$emit('update:modelValue', selectedValue);
                !removedValueWasCustomOption && this.$emit('autoComplete', selectedValue);
            },
            onButtonActionClick() {
                this.$emit('onButtonActionClick');
            },
            onButtonSecondActionClick() {
                this.$emit('onButtonSecondActionClick', this.modelValue);
            }
        }
    }
</script>